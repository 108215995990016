import React from "react"
import Maps from "../components/Maps/maps"
import { Link } from "gatsby"
import tw, { css, styled } from "twin.macro"

import Layout from "../layouts/layout.dk"
import Image from "../components/image"
import SEO from "../components/seo"
import ImageGallery from "../components/features/ImageGallery.jsx"
import EmotionHeader, { Text, Content } from "../components/common"
import Impressum from "../components/features/Impressum"

import { useMapImage } from "../components/hooks/useMapImage"

const IndexPage = props => {
  const mapImage = useMapImage()
  const backgroundFluidImageStack = [
    mapImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.33), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    pageTitle: "Kontakt",
    subTitle: "Wir lieben Kuchen",
    sectionTitle: "Wir lieben Kuchen...",
    seoTitle: "Impressum: Kuchen, Cafe, Frühstück den ganzen Tag",
  }

  const PageContainer = styled.div`
    ${tw`
        bg-white lg:mx-3 text-center
    `}
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 10 12c1.67 0 3.182-.683 4.27-1.785A5.998 5.998 0 0 0 14 12h2a4 4 0 0 1 4-4V6c-1.67 0-3.182.683-4.27 1.785C15.905 7.22 16 6.622 16 6c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 20 6V4a4 4 0 0 1-4-4h-2c0 .622.095 1.221.27 1.785A5.982 5.982 0 0 0 10 0C8.33 0 6.818.683 5.73 1.785 5.905 1.22 6 .622 6 0H4a4 4 0 0 1-4 4v2c1.67 0 3.182.683 4.27 1.785A5.998 5.998 0 0 1 4 6c0-.622.095-1.221.27-1.785A5.982 5.982 0 0 1 0 6v2a4 4 0 0 1 4 4h2zm-4 0a2 2 0 0 0-2-2v2h2zm16 0a2 2 0 0 1 2-2v2h-2zM0 2a2 2 0 0 0 2-2H0v2zm20 0a2 2 0 0 1-2-2h2v2zm-10 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%23de765a' fill-opacity='0.14' fill-rule='evenodd'/%3E%3C/svg%3E");

    padding: 10px;
  `

  const Border = styled.div`
    ${tw`
mx-auto bg-white  p-8 border-gray-400 
`}
    border-width: 1px;
    border-radius: 6px;
  `

  const FlexContainer = styled.div`
    ${tw`
        flex flex-wrap mt-16 mx-8 mb-16
    `}
  `

  return (
    <Layout
      location={props.location}
      showHeader={false}
      imageData={backgroundFluidImageStack}
      title={pageData.pageTitle}
    >
      <SEO title={pageData.sectionTitle} />
      <PageContainer>
        <Content>
          <EmotionHeader
            title="Så nemt er det at finde os "
            subTitle="i Rendsburg"
          />
          <Text>
            <div>
              Vil du gerne reservere et bord? Ring venligst til os for at få
              det. På grund af den nuværende situation kan vi ikke besætte alle
              borde. og beder derfor om et kort opkald (venligst på tysk):
              +49-4331–3398814
            </div>
          </Text>
        </Content>

        <FlexContainer>
          <div css={tw` w-full lg:w-1/2`}>
            <Maps />
          </div>
          <div css={tw` w-full  lg:w-1/2`}>
            <Impressum
              innerStyle={tw`mx-auto bg-white lg:ml-4  rounded-lg shadow-sm p-4 mt-4 lg:mt-0 `}
            />
          </div>
        </FlexContainer>
      </PageContainer>
    </Layout>
  )
}

export default IndexPage
